import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    childOptionsScopeParamName: String,
    childOptionsUrl: String,
    childSelectDomId: String,
    parentSelectDomId: String,
  }

  connect() {
    this.parentSelectElement = document.getElementById(this.parentSelectDomIdValue);
    this.childSelectElement = document.getElementById(this.childSelectDomIdValue);
    // We manually add and remove the change event listener to allow for overlapping
    // connected selects where we may have two cascades (3 connected selects)
    // See https://www.betterstimulus.com/events.html#how-to-cleanup-event-listeners-in-connect--disconnect
    this.boundChange = this.change.bind(this);
    this.parentSelectElement.addEventListener("change", this.boundChange);
    // Observe childSelect for changes in options so that we can support nested
    // connected-selects
    // Create an observer instance linked to the callback function
    this.childSelectOptionsChangeObserver = new MutationObserver(
      (mutationList, observer) => {
        this.childSelectElement.dispatchEvent(new Event("change"));
      }
    );
    this.childSelectOptionsChangeObserver.observe(
      this.childSelectElement,
      { attributes: true, childList: true, subtree: true }
    );
  }

  disconnect() {
    this.parentSelectElement.removeEventListener("change", this.boundChange)
    this.childSelectOptionsChangeObserver.disconnect();
  }

  change(event) {
    let params = new URLSearchParams()
    if(event.target.selectedOptions?.length) {
      params.append(
        this.childOptionsScopeParamNameValue,
        event.target.selectedOptions[0].value
      )
    }
    params.append("target", this.childSelectElement.id)
    get(`${this.childOptionsUrlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
