/* eslint-disable no-unused-vars */
// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import { Tooltip, Popover } from 'bootstrap';
import './channels';
import './controllers';
// import "core-js/stable"
// import "regenerator-runtime/runtime"
import * as ActiveStorage from '@rails/activestorage';
// import Rails from "@rails/ujs"
import $ from 'jquery';
import select2 from 'select2';

// const images = require.context("./images", true)
// const imagePath = (name) => images(name, true)

document.addEventListener('turbo:before-stream-render', (event) => {
  if (event.target.action === 'remove') {
    const targetFrame = document.getElementById(event.target.target);
    if (targetFrame.dataset.animateOut) {
      event.preventDefault();
      const elementBeingAnimated = targetFrame;
      elementBeingAnimated.classList.add(targetFrame.dataset.animateOut);
      elementBeingAnimated.addEventListener('animationend', () => {
        targetFrame.remove();
      });
    }
  }
});

require('@popperjs/core');

document.addEventListener('turbo:load', () => {
  // initialize select2 inputs
  $('.js-select2').select2({ minimumResultsForSearch: 10, theme: 'bootstrap-5' });

  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl);
  });
});
